<template>
  <div class="cursorBox bg000">
    <div v-if="apiShow">
      <PcHeader :parseVal="parseVal" :languageData="languageData" :isSelect="true" />
      <div class="main">
        <div class="vision" id="vision">
          <div class="tit bounceInBox">{{ filterFn('contactUsText', 'title') }}</div>
          <div class="des bounceInBox" v-html="filterFn('contactUsText', 'text')"></div>
          <div class="info-circle">
            <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Email' : 'Email', 'link')" target="_blank" class="item emailBg zoomInLeft animate__animated">
              <span>{{ contactUsFn('contactUs', includesFn ? 'gfg126-Email' : 'Email', 'text') }}</span>
            </a>
            <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Skype' : 'Skype', 'link')" target="_blank" class="item skypeBg zoomInDown animate__animated">
              <span>{{ contactUsFn('contactUs', includesFn ? 'gfg126-Skype' : 'Skype', 'text') }}</span>
            </a>
            <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Telegram' : 'Telegram', 'link')" target="_blank" class="item teleBg zoomInRight animate__animated">
              <span>{{ contactUsFn('contactUs', includesFn ? 'gfg126-Telegram' : 'Telegram', 'text') }}</span>
            </a>
          </div>
        </div>
        <div class="qwrz" id="qw" style="position: relative;">
          <div class="night">
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
          </div>
          <div class="night2">
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
          </div>
          <p class="animate__animated bounceInBox" :class="{ animate__bounceIn: animate18 }">{{ filterFn('officialCertificationText', 'title') }}</p>
          <div class="qw_mid">
            <span class="animate__animated bounceInBox" :class="{ animate__bounceIn: animate19 }">{{ filterFn('officialCertificationText', 'text') }}</span>
          </div>
          <div class="q4box">
            <div style="width: 30%;">
              <div class="animateTxt animate__animated fadeInRight"><div class="q1">
                <img :src="officialCertificationImg('officialCertificationImg', '框1')"/>
              </div>
              {{ filterCertificationFn('officialCertification', 'text', 'tableData1') }}
            </div>
              <div class="animateTxt animate__animated fadeInRight"><div class="q3"><img :src="officialCertificationImg('officialCertificationImg', '框3')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData2') }}</div>
            </div>
            <div style="width: 40%;text-align: center;">
              <img class="animate__animated2 animate__animated fadeInRight" :src="officialCertificationImg('officialCertificationImg', '主图')" />
            </div>
            <div style="width: 30%;">
              <div class="animateTxt animate__animated fadeInRight"><div class="q2"><img :src="officialCertificationImg('officialCertificationImg', '框2')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData3') }}</div>
              <div class="animateTxt animate__animated fadeInRight"><div class="q4"><img :src="officialCertificationImg('officialCertificationImg', '框4')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData4') }}</div>
            </div>
          </div>
        </div>
        <Pcfooter :parseVal="parseVal" />
      </div>
    </div>
  </div>
</template>

<script>
import PcHeader from "@/components/pc/PcHeader2.vue";
import Pcfooter from "@/components/pc/Pcfooter.vue";
import { getList } from '@/api'
export default {
  name: '',
  props: { },
  components: {
    PcHeader,
    Pcfooter
  },
  data() {
    return {
      lang: '',
      animate18: false,
      animate19: false,
      languageData: [],
      parseVal: [],
      apiShow: false,
    }
  },
  methods: {
    loadApi() {
      this.apiShow = false
      getList({ 
        typeString: 'carousel,config,language,guideText,filmText,film,popularGameText,popularGame,propagateText,gamePresentationText,gamePresentation,aboutUsText,aboutUs,officialCertificationText,officialCertification,officialCertificationImg,partnersText,partnersImg,contactUsText,contactUs',
        pageIndex:1, pageSize: 999999, type: 'xxx'
      }).then(res => {
        if (res.data.code) {
          const val = res.data.data.items
          const parseVal = val.map(ele => {
            return { ...ele, ...JSON.parse(ele.jsonString)}
          })
          this.languageData = parseVal.filter(ele => ele.type === 'language')
          
          this.languageData.forEach((ele, index) => {
            if (!localStorage.getItem('LANG_NAME') && index === 0) {
              localStorage.setItem("LANG_NAME", ele.key1);
              this.lang = ele.key1
            }
          })
          if (!(this.languageData.map(ele => ele.key1).includes(localStorage.getItem('LANG_NAME')))) {
            localStorage.setItem("LANG_NAME", this.languageData[0].key1);
            this.lang = this.languageData[0].key1
          }

          this.parseVal = parseVal

        }
      }).finally(() => {
        this.apiShow = true
        this.loadObserve()
      })
    },
    officialCertificationImg(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      console.log(name, 'ss8ss8s8')
      const item = name.find(ele => ele.name === key)
      return item ? ('https://gfgadmin.dr5kr.live/td/api/photo/' + item['computerSrc']) : ''
    },
    filterCertificationFn(val, key, tableName) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.filter(ele => ele.key1 === this.lang)
      const tableItem = item.find(ele => ele.key2 === tableName)
      return tableItem ? tableItem[key] : ''
    },
    filterFn(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.key1 === this.lang)
      return item ? item[key] : ''
    },
    contactUsFn(val, key, contactName) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.name === key)
      return item ? item[contactName] : ''
    },
    loadObserve() {
      this.$nextTick(() => {
        // 文字动画
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__bounceIn')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__bounceIn')
            }
          })
        })
        const cards = document.querySelectorAll('.bounceInBox')
        cards.forEach((card, index) => {
          card.dataset.index = index
          observer.observe(card, index)
        })

        // 向右到左的动画
        const observer2 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__fadeInRight')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__fadeInRight')
            }
          })
        })
        const cards2 = document.querySelectorAll('.fadeInRight')
        cards2.forEach((card, index) => {
          card.dataset.index = index
          observer2.observe(card, index)
        })

        // 联系的动画
        const observer3 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.querySelector('.zoomInLeft').classList.add('animate__lightSpeedInLeft')
              entry.target.querySelector('.zoomInRight').classList.add('animate__lightSpeedInRight')
              entry.target.querySelector('.zoomInDown').classList.add('animate__zoomInDown')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.querySelector('.zoomInLeft').classList.remove('animate__lightSpeedInLeft')
              entry.target.querySelector('.zoomInRight').classList.remove('animate__lightSpeedInRight')
              entry.target.querySelector('.zoomInDown').classList.remove('animate__zoomInDown')
            }
          })
        })
        const cards3 = document.querySelectorAll('.info-circle')
        cards3.forEach((card, index) => {
          card.dataset.index = index
          observer3.observe(card, index)
        })

        // 热门游戏动画
        const observer4 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              if (entry.target.dataset.index < 2) {
                entry.target.classList.add('animate__rotateInDownLeft')
              } else {
                entry.target.classList.add('animate__rotateInDownRight')
              }
            } else {
              // 当元素离开视窗时移除类名
              if (entry.target.dataset.index < 2) {
                entry.target.classList.remove('animate__rotateInDownLeft')
              } else {
                entry.target.classList.remove('animate__rotateInDownRight')
              } 
            }
          })
        })
        const cards4 = document.querySelectorAll('.gameFlipOutYBox')
        cards4.forEach((card, index) => {
          card.dataset.index = index
          observer4.observe(card, index)
        })

        // 动画
        const observer5 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__rubberBand')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__rubberBand')
            }
          })
        })
        const cards5 = document.querySelectorAll('.rubberBandBox')
        cards5.forEach((card, index) => {
          card.dataset.index = index
          observer5.observe(card, index)
        })
      })
    },
  },
  created() {
    this.lang = localStorage.getItem("LANG_NAME")
    this.loadApi()
  },
  mounted() {

  },
  watch: {

  },
  computed: {
    includesFn() {
      return location.host.includes('gfg126')
    }
  }
}
</script>

<style lang="less" scoped>
@import './css/shootingStar.less';
.main{
  // margin-top: 100 ./ @design-width * 95vw;
  overflow: hidden;
}
@design-width: 1920;
.px2vw(@name, @px) {
  @{name}: (@px / @design-width) * 100vw;
}

.vision {
  .px2vw(height, 900);
  width: 100%;
  background: url(~@/assets/img/pc/contactUs/contactbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  padding: 10vw 0 0;
  padding-top: 280 ./ @design-width * 100vw;
  .tit {
    font-family: 'fontTitle';
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0 auto 3.1vw;
    text-align: center;
  }
  .des {
    padding: 0.5vw 22.2vw;
    line-height: 40 ./ @design-width * 100vw;
    font-size: 20 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 1vw;
  }
  #line_video {
    width: 100%;
  }
}
.copyright{
  font-family: "fontText";
  padding: 1rem;
  width: 100%;
  background: #000;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14 ./ @design-width * 100vw;
  text-align: center;
}

.info-circle {
    .px2vw(padding, 30);
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  .item {
    .px2vw(width, 256);
    .px2vw(height, 255);
    position: relative;
    cursor:url('../../assets/img/pc/finger.png'),auto;
    span {
    .px2vw(top, 178);
      color: #fff;
      font-size: 14 ./ @design-width * 100vw;
      position: relative;
      display: block;
      height: auto;
      left: 0;
      width: 70%;
      line-height: 20 ./ @design-width * 100vw;
      word-wrap : break-word ;
      margin: 0 auto;
      text-align: center;
    }
  }
  .teleBg{
    background: url(~@/assets/img/pc/telegrambg.png) center no-repeat;
    background-size: contain;
  }
  .skypeBg{
    background: url(~@/assets/img/pc/skypebg.png) center no-repeat;
    background-size: contain;
  }
  .emailBg{
    background: url(~@/assets/img/pc/emailbg.png) center no-repeat;
    background-size: contain;
  }
}
.qwrz {
  position: relative;
  z-index:1;
  width: 100%;
  // height: 1600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #fafbfd;
  padding-top: 60px;
  background-color: #000;
  // background: url('~@/assets/img/mobile/qwrz.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  // margin-top: -255px;
  > p {
    font-family: 'fontTitle';
    font-size: 45 ./ @design-width * 100vw;
    color: #fff;
    letter-spacing: -1px;
    margin-bottom: 60 ./ @design-width * 100vw;
    font-weight: bold;
    padding-top: 100 ./ @design-width * 100vw;
  }
  .qw_mid {
    width: 60%;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 90 ./ @design-width * 100vw;
    text-align: center;
    span {
      // width: 60%;
      color: #fff;
      padding: 20 ./ @design-width * 100vw;
      line-height: 1.2;
      font-size: 24 ./ @design-width * 100vw;
      color: #999;
    }
    // img {
    //   width: 50%;
    // }
  }
  
  .q4box {
    width: 80%;
    display: flex;
    // flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 24 ./ @design-width * 100vw;
    margin-bottom: 100 ./ @design-width * 100vw;
    z-index: 1; /* 将上方 div 置于上层 */
    // background: url('~@/assets/img/mobile/wizar2.gif)');
    .animate__animated2{
      width: 80%;
      margin: -50 ./ @design-width * 100vw auto 0;
    }
    .animateTxt {
      width: 70%;
      min-height: 282 ./ @design-width * 100vw;
      // font-size: 20px;
      padding: 38 ./ @design-width * 100vw 25 ./ @design-width * 100vw 41 ./ @design-width * 100vw 39 ./ @design-width * 100vw;
      margin: 0 auto 100 ./ @design-width * 100vw;
      line-height: 1.1;
      text-overflow: ellipsis;
      overflow: hidden;
      background: #2D114C;
      opacity: 0.8;
      border-radius: 40 ./ @design-width * 100vw;
      // margin-left: 24px;
      word-break: break-all;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        // width: 199px;
        // height: 39px;
        
        // background: url('~@/assets/img/mobile/q1.png') no-repeat center;
        // background-size: contain;
      }
      .q1 {
        margin-bottom: 30 ./ @design-width * 100vw;
        height: 58 ./ @design-width * 100vw;
      }
      .q2 {
        margin-bottom: 30 ./ @design-width * 100vw;
        height: 44 ./ @design-width * 100vw;
      }
      .q3 {
        margin-bottom: 30 ./ @design-width * 100vw;
        margin-right: 40 ./ @design-width * 100vw;
        height: 56 ./ @design-width * 100vw;
      }
      .q4 {
        margin-bottom: 30 ./ @design-width * 100vw;
        height: 60 ./ @design-width * 100vw;
      }
    }
  }
}
/* 流星特效 */
.night {
  position: relative;
  width: 100%;
  height: 100% ./ @design-width * 100vw;
  transform: rotateZ(145deg);
  position: absolute;
  left: -400  ./ @design-width * 100vw;
  top: 700 ./ @design-width * 100vw;
}

.night2 {
  position: relative;
  width: 100%;
  height: 100% ./ @design-width * 100vw;
  transform: rotateZ(145deg);
  position: absolute;
  left: 440  ./ @design-width * 100vw;
  top: 400 ./ @design-width * 100vw;
}
.cursorBox {
  cursor:url('../../assets/img/pc/cursor.png'),auto;
}
.bg000 {
  background: #000;
  height: 100vh;
}
</style>